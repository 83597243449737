body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#chart-legends::-webkit-scrollbar {
  display: none;
}
#bar-charts::-webkit-scrollbar {
  display: none;
}
.table-2-component::-webkit-scrollbar {

  display: none;
}
code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
  font-family: "Poppins", sans-serif !important;
}
select {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
  font-family: "Poppins", sans-serif !important;
}

/* #profile-list #cardList::-webkit-scrollbar { 
  display: none; 
  } */

/* ===login== */

.login-image {
  width: 100%;
  height: 100vh;
}

.MuiInputBase-root.MuiOutlinedInput-root.Mui-error.Mui-error.MuiInputBase-formControl {
  color: #f44336 !important;
}

.login-container {
  margin-left: 146px;
  margin-right: 146px;
}

.login-title {
  padding-top: 131px;
}

.login-welcome {
  padding-top: 30px;
  color: #33918a;
  font-size: 38px;
}

.login-desc {
  font-size: 12px;
  color: #323132;
}

.form-control {
  padding-top: 15px;
}

.form-control label {
  display: block;
  padding-bottom: 10px;
}

.forgotpass-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}


.privacy-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 40%;
  bottom: 30px;
}

.privacy-container h6 {
  margin: 4px;
  font-size: 14px;
  color: #757575;
}

.privacy-container h6:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* customStyling */
.MuiSelect-select:focus {
  background-color: #fff !important;
}

.MuiToggleButton-root.Mui-selected {
  background-color: #fbe281 !important;
  color: #323132 !important;
}


[class^="MuiButtonBase"]:hover {
  background-color: transparent;
}
/* customStyling */


/* navbar-styling*/
#navbar-menu-item .MuiButton-label {
  justify-content: flex-end;
  width: auto;
}

/* navbar-styling*/

#cardList #profileId:first-child #card {
  flex-direction: row-reverse;
  position: relative;
}
#cardList #profileId:first-child {
  display: flex;
  justify-content: flex-end;
}
#cardList #profileId:first-child #card #profile-list-card-title div {
  justify-content: flex-end;
  /* flex-direction: row-reverse; */
}
#cardList #profileId:first-child #card #profile-list-card-title {
  text-align: end;
  /* flex-direction: row-reverse; */
}
#cardList
  #profileId:first-child
  #card
  #profile-list-card-interaction
  div:last-child {
  margin-right: 5px !important;
}
#cardList
  #profileId:first-child
  #card
  #profile-list-card-interaction
  div:last-child div {
  margin-right: 0px !important;
}
#cardList
  #profileId:first-child
  #card div[class^="MuiCardContent"] {
    margin-left: 0px !important;
    margin-right: 15px;
  }
#cardList #profileId:first-child::after {
  content: "Vs";
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #323132;
  padding: 0px 30px;
}
#cardList #profileId:last-child #profile-list-card-title div {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
#cardList #profileId:last-child #profile-list-card-title #color-code {
  margin-left: 30px;
}
#cardList #profileId:last-child #profile-list-card-interaction div:last-child {
  margin-right: 0px !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #bdbdbd !important;
}
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline  {
  border: 1px solid #bdbdbd !important;
}
 span[class^="MuiButtonBase"] {
  white-space: nowrap !important;
}
 [class^="MuiButton"] {
  white-space: nowrap;
}
#post-card div[class^="MuiCardContent"] p[class^="MuiTypography"], a[class^="MuiTypography"] {
  white-space: inherit;
}
#post-card #captionTrim {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}
/*
#profile-list #cardList #card:first-child::before {
  content: "Vs";
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #323132;
  padding: 0px 30px;
}

#profile-list #card:first-child div:last-child div {
  text-align: end;
  margin-right: 30px;
  justify-content: flex-end;
}

#profile-list #card:first-child #profile-list-card-interaction div:last-child {
  margin-right: 5px !important;
}

#profile-list #card:last-child div:last-child #profile-list-card-title div {
  justify-content: flex-end;
  flex-direction: row-reverse;
}

#profile-list #card:last-child div:last-child #profile-list-card-title div div {
  margin-left: 30px;
}
/* #profile-list #cardList .profileList {
  background-color: #f8c144;
} */
#profile-list #cardList .profileList::after {
  content: "Vs";
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #323132;
  padding: 0px 30px;
}

#profile-list #cardList .profileList:last-of-type::after {
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #323132;
  padding: 0px 30px;
}
*/

/* Table Color */

.MuiTableCell-body {
  color: #323132 !important;
  font-size: 15px !important;
}

/* material date range */
#material-date-range {
  position: absolute;
  font-family: "Poppins", sans-serif;
  right: 0px;
  z-index: 999;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
}
#material-date-range .rdrDateRangePickerWrapper {
  display: flex;
  flex-direction: row-reverse;
}
#material-date-range .rdrDateRangePickerWrapper .rdrDateRangeWrapper .rdrDateDisplayWrapper {
  background-color: #fff;
}
#material-date-range .rdrDateRangePickerWrapper .rdrDateRangeWrapper .rdrDateDisplayWrapper .rdrDateDisplay {
  color: transparent !important;
}
#material-date-range .rdrDateRangePickerWrapper .rdrDateRangeWrapper .rdrDateDisplayWrapper .rdrDateDisplay  .rdrDateInput input {
  font-family: "Poppins", sans-serif;
  color: #343434;
  font-size: 14px;
  font-weight: 600;
}
#material-date-range .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper .rdrStaticRange:hover .rdrStaticRangeLabel, .rdrStaticRange:focus .rdrStaticRangeLabel {
  background-color: transparent;
}
#material-date-range .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper  .rdrStaticRange:hover .rdrStaticRangeLabel, .rdrStaticRange:focus .rdrStaticRangeLabel {
  background-color: transparent;
}
#material-date-range .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper .rdrStaticRange:hover {
  background-color: rgb(255, 248, 222);
}
#material-date-range .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper .rdrStaticRange .rdrStaticRangeLabel{
  padding: 0px 20px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 14px;

}
#material-date-range .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper  {
  width: 180px;
  padding-bottom: 45px;
}
 .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: #323132 !important
}

#material-date-range .rdrDateRangePickerWrapper .rdrDateRangeWrapper .rdrMonths .rdrWeekDays .rdrWeekDay{
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #323132 
}
#material-date-range .rdrDateRangePickerWrapper .rdrDateRangeWrapper .rdrMonths .rdrMonthName {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #323132 
}
#material-date-range .rdrDateRangePickerWrapper .rdrDateRangeWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton {
  width: 32px;
  height: 32px
}
#material-date-range .rdrDateRangePickerWrapper .rdrDateRangeWrapper .rdrMonthAndYearWrapper .rdrMonthPicker select, .rdrYearPicker select{
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #323132;
}


#cardList .profileList:first-child{
  margin-left: auto;
}
#cardList>span{
  margin-right: auto;
}

canvas {
  width: 100% !important;
  height: auto !important;
  position: relative;
  z-index: 0;
}
canvas#emptyPie {
  width: 13vw !important;
  height: 13vw !important;
}
/* #card-labels .MuiGrid-item {
  width: 450px;
} */
#card-labels p {
  white-space: nowrap;
}
#card-labels #social-listening-card #loader-circular {
  justify-content: flex-start;
}
#card-labels #social-listening-card #loader-circular div[class^="MuiCircularProgress-root"] {
  height: 30px !important;
  width: 30px !important;
  margin-top: 20px;
}

/* .makeStyles-profileOverview-14 .MuiGrid-root.makeStyles-row-1.MuiGrid-container div:nth-child(4)>.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-3 {
  justify-content: center;
} */
.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input  {
  border: none;
  background: #fff;
  
}

#contentnewsfeed-selectedprofiles .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  font-size: 14px;
  width: 200px;
  
}

#download-button .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 11px;
}

#card-video div video {
  min-width: 100%;
  max-width: 100%;
  border-radius: 10px;
  object-fit: cover;
}
#card-video iframe {
  border-radius: 10px;

}

#modal-card-video div video {
  min-width: 100%;
  max-width: 100%;
  border-radius: 10px;
  object-fit: cover;
}
#modal-card-video iframe {
  border-radius: 10px;
}

#card-video::after {
  content:'';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index:1000;
  background-color: transparent;
}

/* #card-video div {
  width: 100% !important;
  height: unset !important;
    border-radius: 10px;
} */

/* 
#card-video div iframe {
  border-radius: 10px;
  min-height: 268px;
  max-height: 268px;
}
#card-video div video:focus {
  outline: 0;
} */

/* added profile listing table*/

#profile-list-table
  table[class^="MuiTable"]
  thead[class^="MuiTableHead"] th[class^="MuiTableCell"] {
  padding: 0px;   
}
#profile-list-table
  table[class^="MuiTable"]
  thead[class^="MuiTableHead"] th[class^="MuiTableCell"]:first-child span[class^="MuiButtonBase"] {
  padding-left: 20px;   
}
#profile-list-table
  table[class^="MuiTable"]
  tbody[class^="MuiTableBody"] td[class^="MuiTableCell"] {
  padding: 8px 0px; 
}
#profile-list-table
  table[class^="MuiTable"]
  tbody[class^="MuiTableBody"] tr[class^="MuiTableRow"] {
  position: relative;
}
#profile-list-table
  table[class^="MuiTable"]
  tbody[class^="MuiTableBody"] td[class^="MuiTableCell"] span[class^="MuiButtonBase"] {
  padding: 8px 20px;
  white-space: nowrap; 
}

#profile-list-table
  table[class^="MuiTable"]
  thead[class^="MuiTableHead"] tr[class^="MuiTableRow"] th[class^="MuiTableCell"]:first-child {
    position: sticky;
    min-width: 30px;
    left: 0;
    background-color: #fafafa;
    z-index: 500;
    
  }
  #profile-list-table
  table[class^="MuiTable"]
  thead[class^="MuiTableHead"] tr[class^="MuiTableRow"] th[class^="MuiTableCell"]:last-child svg[class^="MuiSvgIcon-root"] {
   display: none;
    
  }
  #profile-list-table
  table[class^="MuiTable"]
  tbody[class^="MuiTableBody"] tr[class^="MuiTableRow"] td[class^="MuiTableCell"]:first-child {
    position: sticky;
    min-width: 30px;
    left: 0;
    background-color: #fafafa;
    z-index: 500;
    
  } 

/**/
#dialog-action {
width: 100%;
}
#dialog-action button[class^="MuiButtonBase"] {
  width: 100%;
}

/* switch compoennt*/
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #f8c144 !important;
}

/* menu item */
#styled-menu-item .MuiCheckbox-colorSecondary.Mui-checked {
     color: #f8c144;
}

/*snack-bar */
#snack-bar .MuiPaper-root.MuiSnackbarContent-root.MuiPaper-elevation6::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0;
  height: 45px;
  width: 7px;
  background-color: #FBE281;
  border-radius: 4px 0px 0px 4px;
}

/*help Page */
#help-banner-section {
  background: url('./assets/images/faqpage-image.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0px 44%;
  height: 414px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
/*help-page text editor*/
.jodit-container .jodit-workplace {
  min-height: 300px !important;
} 

/* social-listening*/
#sentiments-graph  canvas{
  height: 360px !important;
}
#word-cloud svg {
  height: 430px !important;
}



/* responsive*/
@media only screen and (max-width: 1000px) {
  #cardList #profileId:first-child {
    display: block;
  }
  #cardList #profileId:first-child {
    justify-content: flex-start;
  }
  #cardList #profileId:first-child #card {
    flex-direction: row;
  }
  #cardList #profileId:first-child #card #profile-list-card-title {
    text-align: start;
  }

  #cardList #profileId:first-child #card #profile-list-card-title div {
    justify-content: flex-end;
    flex-direction: row-reverse;
  }
}

@media only screen and (max-width: 800px) {
  .materialui-daterange-picker-MuiGrid-wrap-xs-nowrap-42 {
    flex-wrap: wrap !important;
  }
  #material-date-range .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper  {
    display: none;
  }
  #material-date-range .rdrDateRangePickerWrapper .rdrMonths {
    margin-bottom: 45px;
  }
  #bubble-chart {
    width: 90% !important;
  }
}
@media only screen and (max-width: 1280px) {
  #profile-list-card-interaction div:first-child div  {
    margin-right: 35px !important;
  }
}

@media only screen and (max-width: 1366px) {
  #cardList #profileId:first-child::after {
    content: "Vs";
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    color: #323132;
    padding: 0px 10px;
  }
}
/* @media only screen and (min-width : 1279px) and (max-width : 1365px) {
  .loginWrapper p:last-child, .loginWrapperForm{
    margin: 0 auto !important;
  }
} */

@media only screen and (min-device-width : 1280px) and (max-device-width : 1439px) {
  #cardList #profileId:first-child #card div[class^="MuiCardContent"] {
    margin-right: 0px;
  }
  #cardList #profileId:last-child #card div[class^="MuiCardContent"] {
    margin-left: 0px !important;
  }
  #cardList
  #profileId
  #card
  .MuiAvatar-root
   {
  height: 120px !important;
  width: 120px !important;
}
}
@media only screen and (min-device-width : 1280px) and (max-device-width : 1530px) {
   #styled-dialog [class^="MuiPaper"]  {
    width: 65%;
  }
  #cardList
  #profileId
  #card
  #profile-list-card-interaction
  div {
  margin-right: 10px !important;
}
#cardList
  #profileId:first-child
  #card
  #profile-list-card-interaction
  div:last-child {
  margin-right: 0px !important;
}
#cardList
  #profileId:last-child
  #card
  #profile-list-card-interaction
  div:last-child {
  margin-right: 0px !important;
}
  }

  @media screen and (max-width : 340px)  {
    #material-date-range {
      right: 0 !important;
    }
    #material-date-range .rdrDateRangePickerWrapper .rdrDateRangeWrapper .rdrMonth {
      margin-left: 20px;
      width: 24.667em;
      
    }
  }

  @media screen and (max-width : 375px)  {
    .profileBasicContent {
      left: -30% !important
    }
  }

  @media screen and (max-width : 600px)  {
    #profile-basic-overview-content {
      width: 72vw;
    }
    #custom-color-picker .photoshop-picker {
      width: 90vw !important;
      overflow: hidden;
    }
  }
